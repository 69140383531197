import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentTabBar.figmaUrl.android} codeUrl={checklists.componentTabBar.codeUrl.android} checklists={checklists.componentTabBar.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <p>{`Tabs organize content across different screens, data sets, and other interactions.`}</p>
    <h2>{`Usage`}</h2>
    <p>{`You can implement Tabs by using this tag `}<inlineCode parentName="p">{`LgnTabLayout`}</inlineCode>{` in xml file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.tabbar.LgnTabLayout
    android:id="@+id/tabBase"
    android:layout_width="match_parent"
    android:layout_height="wrap_content"/>
`}</code></pre>
    <p>{`You can alse customize the Tabs in the fragment or activity from Kotlin file .`}</p>
    <p>{`Tabs adapter`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
class CommonPagerAdapters(
    fragment: Fragment,
    val data: List<Fragment>
) : FragmentStateAdapter(fragment) {
    override fun getItemCount(): Int {
        return data.size
    }

    override fun createFragment(position: Int): Fragment {
        return data[position]
    }
}


...
`}</code></pre>
    <p>{`Activity or Fragment file`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
with(binding) {
    ...
    // Configure ViewPager2 with Adapter
    vpBase.adapter = CommonPagerAdapters(listFragment)
    // Attach Tab to ViewPager2
    TabLayoutMediator(this, viewPager2) { tab, position ->
        tab.text = label[position]
    }.attach()
    ...
}

...
`}</code></pre>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Xml Attrs`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Related method(s)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Tab indicator color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`app:tabIndicatorColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`setSelectedTabIndicatorColor()`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Change Tab indicator color`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Tab indicator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`app:tabIndicator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`setSelectedTabIndicatorColor()`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Change Tab indicator`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Tab indicator gravity`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`app:tabIndicatorGravity`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`setSelectedTabIndicatorGravity()`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Change Tab indicator gravity`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Tab indicator animation mode`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`app:tabIndicatorAnimationMode`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.tabIndicatorAnimationMode`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Change Tab indicator animation mode`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Tab mode`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`app:tabMode`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.tabMode`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Change Type of Tab Style`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Tab inline label`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`app:tabInlineLabel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.isInlineLabel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Change Tab Label Position to Inline`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Tab text colors`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`app:tabTextColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.tabTextColors`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Change Tab Text Color State Not Selected`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Tab text colors selected`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`app:tabSelectedTextColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.tabTextColors`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Change Tab Text Color State Not Selected`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Tab padding`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`app:tabPadding`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Change Tab all padding`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Tab padding start`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`app:tabPaddingStart`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Change Tab start padding`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Tab padding top`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`app:tabPaddingTop`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Change Tab top padding`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Tab padding end`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`app:tabPaddingEnd`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Change Tab end padding`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Tab padding bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`app:tabPaddingBottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Change Tab bottom padding`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Tab text appreance`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`app:tabTextAppearance`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Change Tab text apreance`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Tab background`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`app:tabBackground`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Change Tab background`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Tab indicator animation duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`app:tabIndicatorAnimationDuration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Change Tab indicator animation duration]`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      